.inputText {
  padding: 10px;
  max-height: 300px;
  overflow: hidden auto;
  /* max-width: 760px; */
}

.inputText:focus {
  outline: none;
}
