.overflow {
  overflow: scroll;
}

.overflow::-webkit-scrollbar {
  display: none;
}

.overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.select {
  border: "1.5px solid red";
}

.bgImage {
  height: 100%;
}

.remove-scroll::-webkit-scrollbar {
  width: 0px !important;
  display: none !important;
}

@media (min-width: 320px) and (max-width: 992px) {
  .bgImage {
    width: 100%;
    height: 100vh;
    background-size: cover;
  }
}

@media (min-width: 320px) and (max-width: 497px) {
  .bgImage {
    background-image: none;
    width: 100%;
  }
}

.datePickerContainer {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  height: 55px;
  border: 1.5px solid rgba(210, 210, 210, 0.5);
  padding-left: 23px;
  display: flex;
  align-items: center;
  outline: 2px solid transparent;
}

.datePickerContainer:focus {
  border: 2px solid #e2e8f0;
  background-color: #cbd5e0;
  outline: #ccc;
}

.datePickerContainer:hover,
.datePickerContainer:active {
  background-color: #cbd5e0;
}

.customDatePickerIcon {
  position: absolute;
  right: 1rem;
}

.placeholder {
  color: #868b94;
}

.inputPhoneNumber input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.inputPhoneNumber {
  border: 1.5px solid rgba(210, 210, 210, 0.5);
  height: 55px;
  padding-left: 15px;
  border-radius: 10px;
}
.skill_controller input,
.select_controller input,
.controller_select input {
  height: 40px !important;
}

.controller_select {
  border-radius: 10px;
  margin-top: 8px;
  outline: 2px solid transparent;
}
.skill_controller,
.select_controller,
.inputPhoneNumber {
  font-size: 18px;
}

@media (max-width: 1280px) and (min-width: 990px) {
  .inputPhoneNumber {
    font-size: 16px;
  }
  .skill_controller input,
  .select_controller input {
    height: 40px !important;
  }
}

@media (max-width: 990px) {
  .skill_controller input,
  .select_controller input,
  .controller_select input {
    height: 30px !important;
  }
  .datePickerContainer {
    height: 40px !important;
    font-size: 14px;
  }
  .inputPhoneNumber {
    height: 40px;
  }
  .skill_controller,
  .select_controller,
  .inputPhoneNumber {
    font-size: 14px;
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    .datePickerContainer {
      font-size: 12px;
    }
  }
}
