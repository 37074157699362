.bgimg {
    /* background-image: url(../../assets/bgimg/bgwallpaper.png); */
    width: 65%;
    height: 100%;
    background-size: cover;
}

@media (max-width: 1280px) and (min-width: 768px) {
    .bgimg {
        width: 100%;
        height: 100vh;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
    .bgimg {
        /* background-image: url(../../assets/bgimg/bgwallpaper.png); */
        width: 100%;
        height: 100vh;
    }
}

@media (max-width: 1920px) and (min-width: 1919px) {
    .bgimg {
        height: 100vh;
    }
}


.flex_slide {
    width: 100%;
}

.custompage {
    width: 70px;
}

@media (max-width: 1919px) and (min-width: 1280px) {
    .bgimg {
        height: 100vh;
    }
}

@media only screen and (max-width: 1280px) {
    .slickslide {
        width: 100%;
    }
}

@media (max-width: 1355px) and (min-width: 990px) {
    .div_slide {
        width: 120%;
    }
}