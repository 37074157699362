:root {
  --primary-color: #713edd;
  --avatar-size: 40px !important;
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.active-menu-item {
  background-color: var(--primary-color);
  color: white;
}

.upload-icon:hover .font-upload {
  color: #713edd;
}

.font-upload {
  color: #a0a6b1;
}

.scrollBar::-webkit-scrollbar {
  display: none;
}

.scrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

ul.show,
ol.show {
  padding-left: 20px;
}

/* ul:not(.show) li::marker,
ol:not(.show) li::marker {
  display: none !important;
  font-size: 0px;
  padding-bottom: 20px;
} */

.css-13cymwt-control,
.css-16xfy0z-control {
  border-radius: 10px !important;
  border: 1.5px solid rgba(210, 210, 210, 0.5) !important;
}

.css-t3ipsp-control {
  z-index: 1 !important;
  border-color: #713edd !important;
  box-shadow: 0 0 0 1px #713edd !important;
  border-radius: 10px !important;
}

.css-tj5bde-Svg {
  color: #713edd !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.css-pv22qu {
  min-width: 60% !important;
  border-radius: 10px !important;
  background: transparent !important;
  box-shadow: none !important;
}

.css-a5mhaz {
  padding: 0px !important;
}

/* For gantt view of board */

._2dZTy:nth-child(even) {
  fill: none !important;
}

._34SS0:nth-of-type(even) {
  background: none !important;
}

._34SS0 div {
  font-size: 14px !important;
  color: rgba(134, 139, 148, 1) !important;
}

._31ERP {
  fill: rgba(113, 62, 221, 1);
}

._3zRJQ {
  font-weight: 500 !important;
}

._1nBOt div:nth-child(2),
._1nBOt div:nth-child(3),
._1nBOt div:nth-child(4),
._1nBOt div:nth-child(5),
._34SS0 ._3lLk3:nth-of-type(2),
._34SS0 div:nth-child(3) {
  display: none !important;
}

._1nBOt {
  text-align: center !important;
}

._nI1Xw {
  justify-content: center !important;
}

._2k9Ys::-webkit-scrollbar-thumb {
  display: none !important;
}

.gridBody {
  stroke-dasharray: 5, 5;
}

._WuQ0f {
  vertical-align: bottom !important;
  padding-bottom: 2px !important;
  color: rgba(32, 43, 60, 1) !important;
}

._9w8d5 {
  font-size: 14px !important;
  fill: rgba(134, 139, 148, 1) !important;
  font-family: "poppins" !important;
}

._2q1Kt {
  fill: rgba(32, 43, 60, 1) !important;
  font-weight: 600;
  padding-bottom: 5px;
}

._3ZbQT,
._3_ygE,
.calendar,
.bar {
  font-family: "poppins" !important;
}

.bar {
  font-weight: 500;
}

.fa-angle-down {
  height: auto !important;
}

.fa-check {
  height: auto !important;
}

.rct-scroll {
  width: 100% !important;
}

.react-calendar-timeline .rct-header-root {
  background: white !important;
}

/* ._25P-K {
  left: 39% !important;
  top: 100px !important;
  position: absolute !important;
} */

/* 
.fc-scrollgrid-sync-table {
  width: 100% !important;
  height: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc .fc-scroller-liquid-absolute {
  position: unset !important;
}

.fc-daygrid-body-unbalanced {
  width: auto !important;
} */
.ellipsis {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.paginationButtons {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationButtons li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.previous ~ li {
  margin: 0 0 0 2px;
}

.paginationButtons .previous a,
.paginationButtons .next a {
  width: unset;
}

.paginationButtons a {
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paginationButtons a:hover {
  color: white;
}
.paginationButtons :hover {
  color: white !important;
}
.paginationButtons li:hover a {
  color: white !important;
  background: linear-gradient(134.59deg, #a17dee -1.98%, #713edd 111.17%);
  border-radius: 8px !important;
}

.next:hover {
  display: block;
  color: white !important;
}

.paginationActive {
  color: white;
  background: linear-gradient(134.59deg, #a17dee -1.98%, #713edd 111.17%);
}

.h1_font {
  font-size: 34px !important;
  font-weight: bold !important;
}

.ul_font:where(ol, ul) {
  margin: auto !important;
  padding-left: 40px !important;
}

.ol_font:where(ol, ul) {
  margin: auto !important;
  padding-left: 40px !important;
}
.li_font::marker {
  font-size: 14px !important;
}

.h2_font {
  font-size: 30px !important;
  font-weight: bold !important;
}
.paginationActive a {
  color: white;
}

.blockquote_font {
  display: block !important;
  margin-inline-start: 40px !important;
  margin-inline-end: 40px !important;
  unicode-bidi: isolate !important;
}

.previous {
  position: absolute;
  left: 0;
}
.next {
  position: absolute;
  right: 0;
}
.previous:hover {
  background-color: #a17dee;
  color: white;
}
.next:hover {
  background-color: #a17dee;
  color: white;
}
.paginationButtons li:nth-child(1) {
  width: 128px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
}
.paginationButtons li:last-child {
  width: 99px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
}

.paginationDisabled {
  pointer-events: none !important;
}

#infiniteScrollContainer::-webkit-scrollbar {
  display: none;
}

#chat-history-scroller::-webkit-scrollbar {
  display: none;
}

#chat-history-scroller * {
  overflow-anchor: none;
}

.chat-history-scroller #anchor {
  overflow-anchor: auto;
  height: 30px;
}

.slate-editor ul,
.slate-editor ol {
  padding-left: 30px;
}

.slate-editor ul li::marker,
.slate-editor ol li::marker {
  display: initial !important;
  font-size: initial;
}

.slate-editor ul {
  list-style: disc;
}

.slate-editor ol {
  list-style: auto;
}

.message-preview h1,
.message-preview h2,
.message-preview h3,
.message-preview h4,
.message-preview h5,
.message-preview h6 {
  font-size: revert;
  font-weight: revert;
  margin: revert;
}

.message-preview p {
  /* margin: revert; */
  margin-top: 0;
}

.message-preview p:empty {
  height: 1rem;
}

.message-preview ul,
.message-preview ol {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.message-preview li::marker {
  display: block !important;
}

.message-preview a {
  color: revert;
  text-decoration: revert;
}
